import { useDisclosure } from '@mantine/hooks';
import { useEffect, useRef, useState, type FunctionComponent } from 'react';
import { useAnalytics } from '~/hooks';
import styles from '~/styles/shared/inspectionCertificateScanModal/capture.module.css';
import { CameraAccessPopup } from '../CameraAccessPopup/CameraAccessPopup';
import { CaptureNotice } from './CaptureNotice';

const blockClass = 'capture';
const certificateScannerMediaConstraint = {
  audio: false,
  video: {
    width: 3000,
    height: 2000,
    facingMode: 'environment'
  }
};
const headerHeight = 68;

type SrcDimension = {
  x: number;
  y: number;
  w: number;
  h: number;
};

type Props = {
  onCaptureImage: (imageUrl: string) => void;
  onUploadedImage: ({ contentToken, imageUrl }: {contentToken: string;imageUrl: string;}) => void;
  setShowLoading: (showLoading: boolean) => void;
  onError: () => void;
};

export const Capture: FunctionComponent<Props> = (props) => {
  const { onCaptureImage, onUploadedImage, setShowLoading, onError } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const cornersRef = useRef<HTMLDivElement>(null);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [accessPopupOpened, { open: accessPopupOpen, close: accessPopupClose }] = useDisclosure(false);
  const [isBlockedCamera, setIsBlockedCamera] = useState(false);
  const { sendTrackEvent } = useAnalytics();

  useEffect(() => {
    let media: MediaStream;

    const onPlayed = () => {
      const srcDimension = getSrcDimension();
      const scaleSrcToCanvas = getScaleSrcToCanvas();

      if (!scaleSrcToCanvas || !srcDimension) {
        return;
      }

      setCanvasWidth(scaleSrcToCanvas.h * srcDimension.h);
      setCanvasHeight(scaleSrcToCanvas.w * srcDimension.w);
    };

    (async () => {
      try {
        media = await navigator.mediaDevices.getUserMedia(certificateScannerMediaConstraint);
      } catch (e) {
        accessPopupOpen();
        setIsBlockedCamera(true);
        return;
      }

      if (!videoRef.current) {
        return;
      }

      videoRef.current.srcObject = media;
      videoRef.current.onloadedmetadata = () => videoRef?.current?.play();
      videoRef.current.onplay = onPlayed;
    })();

    return () => {
      media?.getTracks().forEach((track) => track.stop());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
    x: 枠の左上のx座標
    y: 枠の左上のy座標
    w: 枠の幅
    h: 枠の高さ
  */
  const getSrcDimension = (): SrcDimension | undefined => {
    if (!cornersRef.current || !videoRef.current) {
      return;
    }
    const cornersElementRect = cornersRef.current.getBoundingClientRect();
    const cornersElementLeftFromCenter = window.innerWidth / 2 - cornersElementRect.left;
    const videoClientWidth = videoRef.current.clientWidth;

    return {
      x: videoClientWidth / 2 - cornersElementLeftFromCenter,
      y: cornersElementRect.top - headerHeight,
      w: cornersRef.current.clientWidth,
      h: cornersRef.current.clientHeight
    };
  };

  // videoタグ上の映像と実際の映像の比率
  const getScaleSrcToCanvas = (): {w: number;h: number;} | undefined =>
  videoRef.current ?
  {
    w: videoRef.current.videoWidth / videoRef.current.clientWidth,
    h: videoRef.current.videoHeight / videoRef.current.clientHeight
  } :
  undefined;

  const onLoadMetaData = (event: React.SyntheticEvent<HTMLVideoElement, Event>): void => {
    const video = event.target as HTMLVideoElement;
    video.play();
  };

  // 枠内の映像をキャプチャしてcanvasに描画
  const capture = () => {
    const promise = new Promise<string | undefined>((resolve) => {
      const srcDimension = getSrcDimension();
      const scaleSrcToCanvas = getScaleSrcToCanvas();

      if (!scaleSrcToCanvas || !srcDimension || !videoRef.current || !canvasRef.current) {
        return resolve(undefined);
      }

      const context = canvasRef.current.getContext('2d');
      if (!context) {
        return resolve(undefined);
      }

      context.save();
      context.rotate(-90 * Math.PI / 180);
      context.drawImage(
        videoRef.current,
        scaleSrcToCanvas.w * srcDimension.x,
        scaleSrcToCanvas.h * srcDimension.y,
        scaleSrcToCanvas.w * srcDimension.w,
        scaleSrcToCanvas.h * srcDimension.h,
        -1 * scaleSrcToCanvas.w * srcDimension.w,
        0,
        scaleSrcToCanvas.w * srcDimension.w,
        scaleSrcToCanvas.h * srcDimension.h
      );
      videoRef.current.pause();
      context.restore();
      const data = canvasRef.current.toDataURL('image/jpeg', 1.0);
      return resolve(data);
    });

    return promise;
  };

  const handleClickCaptureButton = async () => {
    sendTrackEvent('click_selling_form_camera_capture_button');
    const imageUrl = await capture();
    if (!imageUrl) {
      onError();
      return;
    }
    onCaptureImage(imageUrl);
  };

  return (
    <div className={styles[blockClass]}>
      <video
        className={styles[`${blockClass}__video`]}
        ref={videoRef}
        autoPlay
        playsInline
        muted
        onLoadedMetadata={(event) => onLoadMetaData(event)} />

      <CaptureNotice onUploadedImage={onUploadedImage} setShowLoading={setShowLoading} onError={onError} />
      <div className={styles[`${blockClass}__corners`]} ref={cornersRef}>
        <div className={[styles[`${blockClass}__corner`], styles[`${blockClass}__corner--top-left`]].join(' ')} />
        <div className={[styles[`${blockClass}__corner`], styles[`${blockClass}__corner--top-right`]].join(' ')} />
        <div className={[styles[`${blockClass}__corner`], styles[`${blockClass}__corner--bottom-left`]].join(' ')} />
        <div className={[styles[`${blockClass}__corner`], styles[`${blockClass}__corner--bottom-right`]].join(' ')} />
      </div>

      <div className={[styles[`${blockClass}__overlay`], styles[`${blockClass}__overlay-top`]].join(' ')} />
      <div className={[styles[`${blockClass}__overlay`], styles[`${blockClass}__overlay-left`]].join(' ')} />
      <div className={[styles[`${blockClass}__overlay`], styles[`${blockClass}__overlay-right`]].join(' ')} />
      <div className={[styles[`${blockClass}__overlay`], styles[`${blockClass}__overlay-bottom`]].join(' ')} />
      <p className={[styles[`${blockClass}__guide`], styles[`${blockClass}__guide-left`]].join(' ')}>車検証下</p>
      <p className={[styles[`${blockClass}__guide`], styles[`${blockClass}__guide-right`]].join(' ')}>車検証上</p>

      <button className={styles[`${blockClass}__button`]} onClick={handleClickCaptureButton} disabled={isBlockedCamera}>
        <div className={styles[`${blockClass}__button-circle`]} />
      </button>
      <canvas className={styles[`${blockClass}__canvas`]} ref={canvasRef} width={canvasWidth} height={canvasHeight} />
      {accessPopupOpened && <CameraAccessPopup close={accessPopupClose} />}
    </div>);

};