import { useState, type FunctionComponent } from 'react';
import { useAnalytics, useS3DirectUpload } from '~/hooks';
import styles from '~/styles/shared/inspectionCertificateScanModal/capture-notice.module.css';

const blockClass = 'capture-notice';

type Props = {
  onUploadedImage: ({ contentToken, imageUrl }: {contentToken: string;imageUrl: string;}) => void;
  setShowLoading: (showLoading: boolean) => void;
  onError: () => void;
};

export const CaptureNotice: FunctionComponent<Props> = (props) => {
  const { onUploadedImage, setShowLoading, onError } = props;
  const [open, setOpen] = useState(true);
  const { directUpload } = useS3DirectUpload();
  const { sendTrackEvent } = useAnalytics();

  if (!open) {
    return <></>;
  }

  const uploadInspectionImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    sendTrackEvent('click_selling_form_camera_upload_button');
    setShowLoading(true);

    const files = e.target.files;
    const file = files?.[0];
    if (!file) {
      setShowLoading(false);
      return;
    }

    try {
      const { contentToken } = await directUpload(file);
      if (!contentToken) {
        setShowLoading(false);
        onError();
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      onUploadedImage({ contentToken, imageUrl });
      setShowLoading(false);
    } catch (e) {
      setShowLoading(false);
      onError();
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__top`]}>
        <div className={styles[`${blockClass}__info`]} />
        <p className={styles[`${blockClass}__text`]}>
          車検証をオレンジの枠内に合わせて
          <br />
          縦向きで撮影してください
        </p>
        <div className={styles[`${blockClass}__close`]} onClick={() => setOpen(false)} />
      </div>
      <label htmlFor='inspection-image' className={styles[`${blockClass}__label`]}>
        <span className={styles[`${blockClass}__upload`]}>保存済みの写真を添付する方はこちら</span>
        <input
          type='file'
          id='inspection-image'
          onChange={uploadInspectionImage}
          accept='image/*'
          style={{ display: 'none' }} />

      </label>
    </div>);

};