import { type FunctionComponent } from 'react';
import { LoginLink } from '~/components/parts';
import styles from '~/styles/shared/login-retry-modal.module.css';
import { Modal } from '../Modal/Modal';

const blockClass = 'login-retry-modal';

type Props = {
  opened: boolean;
  redirectPath: string;
};

export const LoginRetryModal: FunctionComponent<Props> = (props) => {
  const { opened, redirectPath } = props;

  return (
    <Modal
      isOpen={opened}
      className={styles[blockClass]}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 201
        }
      }}>

      {opened &&
      <>
          <p className={styles[`${blockClass}__title`]}>LINEログイン</p>
          <div className={styles[`${blockClass}__description`]}>
            <p>
              下記画面が表示された場合は
              <br />
              「LINEアプリでログイン」をタップすると
              <br />
              自動でログインすることができます。
            </p>
          </div>
          <div className={styles[`${blockClass}__image`]}>
            <img src='/images/pictures/line_login_page.png' alt='LINEログイン' />
          </div>
          <LoginLink className={styles[`${blockClass}__link`]} redirectPath={redirectPath}>
            LINEログインに進む
          </LoginLink>
        </>
      }
    </Modal>);

};