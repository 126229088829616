import { type FunctionComponent } from 'react';
import { LoginLink } from '~/components/parts';
import { useLineFavoriteModalContext } from '~/providers';
import styles from '~/styles/shared/line-favorite-modal.module.css';
import { Modal } from '..';

const blockClass = 'line-favorite-modal';

export const LineFavoriteModal: FunctionComponent = () => {
  const { opened, close } = useLineFavoriteModalContext();

  return (
    <Modal
      isOpen={opened}
      className={styles[blockClass]}
      onRequestClose={close}
      style={{
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 201
        }
      }}>

      {opened &&
      <>
          <div className={styles[`${blockClass}__close`]} onClick={close}>
            <img src={'/images/icons/ic_close.svg'} alt='close' />
          </div>
          <p className={styles[`${blockClass}__title`]}>＼ LINE連携でお気に入り登録可能 ／</p>
          <LoginLink className={styles[`${blockClass}__button`]} onClick={close} isNewRegister={true}>
            <img src={'/images/icons/ic_line.svg'} className={styles[`${blockClass}__button-icon`]} alt='line' />
            <span className={styles[`${blockClass}__button-text`]}>LINE連携する</span>
          </LoginLink>
          <hr className={styles[`${blockClass}__hr`]} />
          <LoginLink className={styles[`${blockClass}__link`]} onClick={close}>
            アカウントをお持ちの方はこちら
          </LoginLink>
        </>
      }
    </Modal>);

};