import { useTimeout } from '@mantine/hooks';
import { useEffect, useState, type FunctionComponent } from 'react';
import { Modal } from '~/components/shared';
import styles from '~/styles/shared/inspectionCertificateScanModal/inspection-certificate-scan-modal.module.css';
import { Capture } from './Capture';
import { CaptureConfirm } from './CaptureConfirm';

const blockClass = 'inspection-certificate-scan-modal';

type Props = {
  opened: boolean;
  close: () => void;
  onUploaded: ({ contentToken, imageUrl }: {contentToken: string;imageUrl: string;}) => void;
};

export const InspectionCertificateScanModal: FunctionComponent<Props> = (props) => {
  const { opened, close, onUploaded } = props;
  const [captureImageUrl, setCaptureImageUrl] = useState<string | null>(null);
  const [showLoading, setShowLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { start: startCloseTimer } = useTimeout(() => setHasError(false), 2000);

  useEffect(() => {
    if (!hasError) return;
    startCloseTimer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  const handleClickClose = () => {
    setCaptureImageUrl(null);
    close();
  };

  const handleOnUploadedImage = ({ contentToken, imageUrl }: {contentToken: string;imageUrl: string;}) => {
    onUploaded({ contentToken, imageUrl });
    setCaptureImageUrl(null);
  };

  return (
    <Modal
      isOpen={opened}
      className={styles[blockClass]}
      onRequestClose={close}
      style={{
        overlay: {
          zIndex: 201,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }
      }}>

      {opened &&
      <div style={{ height: '100%' }}>
          <div className={styles[`${blockClass}__header`]}>
            <p className={styles[`${blockClass}__header-text`]}>{captureImageUrl ? '車検証の確認' : '車検証の撮影'}</p>
            <div className={styles[`${blockClass}__header-close`]} onClick={handleClickClose} />
            {hasError &&
          <p className={styles[`${blockClass}__header-error`]}>エラーが発生しました。もう一度お試しください。</p>
          }
          </div>
          {captureImageUrl ?
        <CaptureConfirm
          imageUrl={captureImageUrl}
          backToCapture={() => setCaptureImageUrl(null)}
          onUploadedImage={handleOnUploadedImage}
          onError={() => setHasError(true)} /> :


        <Capture
          onCaptureImage={(imageUrl: string) => setCaptureImageUrl(imageUrl)}
          onUploadedImage={handleOnUploadedImage}
          setShowLoading={setShowLoading}
          onError={() => setHasError(true)} />

        }
          {showLoading &&
        <div className={styles[`${blockClass}__loading`]}>
              <div className={styles[`${blockClass}__loader`]} />
            </div>
        }
        </div>
      }
    </Modal>);

};