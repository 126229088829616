import { useDisclosure, useWindowScroll } from '@mantine/hooks';
import { Link } from '@remix-run/react';
import { useEffect, useState, type FunctionComponent } from 'react';
import { useAuth } from '~/hooks';
import styles from '~/styles/layout/header.module.css';
import { CarSearchModal } from '../shared/CarSearchModal/CarSearchModal';

export const Header: FunctionComponent = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { user } = useAuth();
  const favoritedCarStocks = user?.favoritedCarStocks || [];
  const [isHide, setIsHide] = useState(false);
  const [scroll] = useWindowScroll();
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);

  useEffect(() => {
    if (scroll.y <= 0) {
      setIsHide(false);
      setCurrentScrollPosition(0);
      return;
    }

    setIsHide(scroll.y > currentScrollPosition);
    setCurrentScrollPosition(scroll.y);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll]);

  return (
    <header className={[styles.header, isHide ? styles['header-hidden'] : ''].join(' ')}>
      <div className={styles.header__inner}>
        <div className={styles.header__row}>
          <Link to='/' className={styles.header__logo}>
            {/* <div className={styles.header__title}>ダマさないから、ネットで売れる。</div> */}
            <img src={'/images/logos/logo_black.svg'} alt='logo' />
          </Link>
          <div className={styles.header__right}>
            <Link
              to='/favorites'
              className={`${styles.header__favorite} ${favoritedCarStocks.length > 0 ? styles['with-badge'] : ''}`}>

              <img src={'/images/icons/ic_favorite.svg'} alt='お気に入り一覧' width={24} />
            </Link>
            <img
              src={'/images/icons/ic_search.svg'}
              alt='検索'
              onClick={open}
              className={styles.header__search}
              width={24} />

          </div>
        </div>
      </div>
      <CarSearchModal opened={opened} close={close} />
    </header>);

};