import { type CombinedError } from 'urql';
import * as Sentry from '@sentry/remix';

type DataErrors =
  | {
      errors?: Array<{ __typename?: 'ClientError'; message: string; path?: Array<string> | null }>;
    }
  | undefined
  | null;

type ErrorHandleResponse = {
  hasError: boolean;
  message?: string;
};

export const errorHandle = <T extends DataErrors>(data?: T, error?: CombinedError): ErrorHandleResponse => {
  if (data?.errors?.length) {
    console.error(data.errors);
    Sentry.captureException(new Error(data.errors.map((error) => error.message).join('\n')));
    return { hasError: true, message: data.errors[0].message };
  }

  if (error?.graphQLErrors?.length) {
    console.error(error.graphQLErrors);
    Sentry.captureException(new Error(error.graphQLErrors.map((error) => error.message).join('\n')));
    return { hasError: true, message: error.graphQLErrors[0].message };
  }

  if (error?.networkError) {
    console.error(error.networkError);
    Sentry.captureException(error.networkError);
    return { hasError: true, message: error.networkError.message };
  }

  return { hasError: false };
};
