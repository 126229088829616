import { Link, useLocation } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import styles from '~/styles/layout/footer.module.css';
import { MiniappShortcutBanner } from '../shared/banners';

const blockClass = 'footer';

export const Footer: FunctionComponent = () => {
  const location = useLocation();
  const isTop = location.pathname === '/';

  return (
    <footer className={styles[blockClass]}>
      <ul className={styles[`${blockClass}__sns`]}>
        <li className={styles[`${blockClass}__sns-item`]}>
          <Link to='https://www.youtube.com/@yuusaku_buddica'>
            <img src={'/images/icons/ic_youtube.svg'} alt='Youtube クルマの通販「BUDDICAダイレクト」' />
          </Link>
        </li>
        <li className={styles[`${blockClass}__sns-item`]}>
          <Link to='/add_line_friend'>
            <img src={'/images/icons/ic_line_foot.svg'} alt='LINEで友達を追加する' />
          </Link>
        </li>
        <li className={styles[`${blockClass}__sns-item`]}>
          <Link to='https://twitter.com/yuusaku_buddica'>
            <img src={'/images/icons/ic_x.svg'} alt='Twitter' />
          </Link>
        </li>
      </ul>
      <div className={styles[`${blockClass}__main`]}>
        <Link to='/' className={styles.footer__logo}>
          <p className={styles[`${blockClass}__logo-text`]}>ダマさないから、ネットで売れる。</p>
          <img
            src={'/images/logos/logo.svg'}
            className={styles[`${blockClass}__logo-image`]}
            alt='バディカダイレクト' />

        </Link>
        <ul className={styles[`${blockClass}__nav`]}>
          {!isTop &&
          <>
              <li className={styles[`${blockClass}__nav-item`]}>
                <Link to='/selling/'>クルマを売りたい方はこちら</Link>
              </li>
              <li className={styles[`${blockClass}__nav-item`]}>
                <Link to='/request/'>クルマのリクエストはこちら</Link>
              </li>
            </>
          }

          <li className={styles[`${blockClass}__nav-item`]}>
            <Link to='/guide/'>バディカダイレクトとは？</Link>
          </li>
          <li className={styles[`${blockClass}__nav-item`]}>
            <Link to='/company/'>運営会社</Link>
          </li>
          <li className={styles[`${blockClass}__nav-item`]}>
            <a
              href='https://sapphire-empress-855.notion.site/Buddica-Direct-2e2845f59b494eab887307873163c968'
              target='_blank'
              rel='noopener noreferrer'>

              採用情報
            </a>
          </li>
        </ul>
      </div>
      <div className={styles[`${blockClass}__sub`]}>
        <ul className={styles[`${blockClass}__subNav`]}>
          <li className={styles[`${blockClass}__subNav-item`]}>
            <Link to='/privacy/'>プライバシーポリシー</Link>
          </li>
          <li className={styles[`${blockClass}__subNav-item`]}>
            <Link to='/terms/'>利用規約</Link>
          </li>
          <li className={styles[`${blockClass}__subNav-item`]}>
            <Link to='/commerce-law/'>特定商取引法に基づく表記</Link>
          </li>
        </ul>

        <p className={styles[`${blockClass}__copy`]}>
          <small>&copy; BUDDICA・DIRECT.CO., LTD. 古物商許可番号 香川県公安委員会 第811080001925号</small>
        </p>
        <MiniappShortcutBanner />
      </div>
    </footer>);

};