import { useState, type FunctionComponent } from 'react';
import { useAnalytics, useS3DirectUpload } from '~/hooks';
import styles from '~/styles/shared/inspectionCertificateScanModal/capture-confirm.module.css';

const blockClass = 'capture-confirm';

type Props = {
  imageUrl: string;
  backToCapture: () => void;
  onUploadedImage: ({ contentToken, imageUrl }: {contentToken: string;imageUrl: string;}) => void;
  onError: () => void;
};

export const CaptureConfirm: FunctionComponent<Props> = (props) => {
  const { imageUrl, backToCapture, onUploadedImage, onError } = props;
  const { directUpload } = useS3DirectUpload();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { sendTrackEvent } = useAnalytics();

  const changeImageUrlToFile = async () => {
    return new Promise<File>((resolve, reject) => {
      fetch(imageUrl).
      then((response) => response.blob()).
      then((blob) => new File([blob], 'inspectionImage.jpg')).
      then((file) => resolve(file)).
      catch(() => reject('error'));
    });
  };

  const uploadInspectionImage = async () => {
    sendTrackEvent('click_selling_form_camera_register_button');

    try {
      setIsSubmitting(true);
      const file = await changeImageUrlToFile();
      const { contentToken } = await directUpload(file);
      if (!contentToken) {
        setIsSubmitting(false);
        onError();
        return;
      }
      onUploadedImage({ contentToken, imageUrl });
    } catch (e) {
      setIsSubmitting(false);
      onError();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__image`]}>
        <img src={imageUrl} alt='車検証画像' />
      </div>
      <div className={styles[`${blockClass}__notice`]}>
        <p className={styles[`${blockClass}__notice-text`]}>
          車検証全体が収まっていて、文字がはっきり読み取れますか？
          <br />
          問題なければ写真を登録してください
        </p>
      </div>
      <button className={styles[`${blockClass}__button`]} onClick={uploadInspectionImage} disabled={isSubmitting}>
        登録する
      </button>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <span className={styles[`${blockClass}__link`]} onClick={backToCapture}>
          撮影しなおす
        </span>
      </div>
    </div>);

};