import { Link } from '@remix-run/react';
import type { FunctionComponent } from 'react';
import styles from '~/styles/shared/banners/tel-consultation-banner.module.css';

export const TelConsultationBanner: FunctionComponent = () => {
  return (
    <Link to='/add_line_friend?inflow_source_message=call'>
      <div className={styles.banner}>
        <div className={styles.banner__inner}>
          <div>
            <h3 className={styles.banner__title}>本日の電話相談</h3>
            <p className={styles.banner__open}>10:00~19:00</p>
          </div>
          <img src={'/images/icons/ic_onlinestaff.png'} alt='接客スタッフオンライン中 LINE電話相談' width={100} />
          <div className={styles.banner__button}>
            <img src={'/images/icons/ic_phone.svg'} className={styles.banner__button__icon} alt='line電話' />
            <span className={styles.banner__button__text}>LINE電話相談</span>
          </div>
        </div>
      </div>
    </Link>);

};