import type { FunctionComponent } from 'react';
import styles from '~/styles/shared/inspection-certificate-sample-modal.module.css';
import { Modal } from '..';

type Props = {
  opened: boolean;
  close: () => void;
};

export const InspectionCertificateSampleModal: FunctionComponent<Props> = (props) => {
  const { opened, close } = props;

  return (
    <Modal
      isOpen={opened}
      onRequestClose={close}
      className={styles.main}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 201
        }
      }}>

      <div className={styles.close} onClick={close}>
        <img src={'/images/icons/ic_close.svg'} alt='close' />
      </div>
      <div className={styles['image-block']}>
        <p className={styles['first-title']}>2023年1月4日以降に発行された車検証</p>
        <div className={styles['first-image']}>
          <img src={'/images/pictures/inspection_certificate_sample_1.png'} alt='車検証サンプル1' />
        </div>
      </div>
      <div className={styles['image-block']}>
        <p className={styles['second-title']}>2023年1月3日以前に発行された車検証</p>
        <div className={styles['second-image']}>
          <img src={'/images/pictures/inspection_certificate_sample_2.png'} alt='車検証サンプル1' />
        </div>
      </div>
      <div className={styles.notice}>
        <img src='/images/icons/ic_caution_yellow.svg' alt='caution' className={styles.icon} />
        <p className={styles.text}>車両番号（ナンバー）以外は隠さないでください。</p>
      </div>
    </Modal>);

};